import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import CatList from '../catList/CatList'

const EventGridStyles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
`

const EventStyles = styled.div`
  margin-bottom: 20px;

  width: 100%;
  /* height: 100%; */
  padding: 10px 20px;
  border: 1px solid black;
  box-shadow: 2px 2px 5px #999;

  .title {
    margin-bottom: 5px;
  }

  .date {
    font-style: italic;
    margin-bottom: 10px;
  }

  .image {
    width: 100%;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: 140px;
      width: 100px;
      overflow: hidden;
      /* margin: 0 auto; */

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .description {
    text-align: center;
    margin-bottom: 35px;
  }

  .catList {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-self: center;
    align-self: flex-end;
    margin-bottom: 15px;
    /* float: right; */
  }

  @media all and (min-width: 700px) {
    width: 400px;
  }

  @media all and (min-width: 1136px) {
  }
`

const formatDate = date => {
  const dateStr = new Date(date).toLocaleDateString('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  return dateStr
}

function SingleEvent({ event }) {
  const dateStr = formatDate(event.eventDate)

  const image = event?.eventImage || event?.legacyImage

  return (
    <EventStyles className="hover">
      <Link className="subtle" to={`/events/${event.slug.current}`}>
        <h2 className="title">{event.title}</h2>
        <h5 className="date">{dateStr}</h5>
        <div className="image">
          <Img fluid={image.asset.fluid} alt={image?.alt || event.title} />
        </div>

        <p className="description">{event.description}</p>
      </Link>
      <div className="catList">
        <CatList
          categories={event.categories}
          keyName={event.id}
          align="center"
        />
      </div>
    </EventStyles>
  )
}

export default function EventList({ events }) {
  return (
    <EventGridStyles>
      {events.map(event => (
        <SingleEvent key={event.id} event={event} />
      ))}
    </EventGridStyles>
  )
}
