import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import EventList from '../components/lists/EventList'
import Calendar from '../components/calendar/calendar'
import CatNavList from '../templates/CategoryNavList'
import { filterForCat, filterEventsByQuery } from '../utils/utils'

const CalendarStyles = styled.div`
  /* width: 100%; */
`

const filterByDate = events =>
  events.filter(item => new Date(item.endDate) >= new Date())

export default function EventsPage({ data, location }) {
  const events = data.events.nodes
  const filteredByDate = filterByDate(events)
  let filteredEvents = filterForCat(filteredByDate, location)

  filteredEvents = filterEventsByQuery({
    list: filteredEvents,
    location,
    filterQueryName: 'event',
  })

  return (
    <div className="content-width">
      <h1 className="title">Pioneer Events</h1>
      <CatNavList
        items={filteredByDate}
        location={location}
        baseUrl="/events"
      />
      <CalendarStyles>
        <Calendar events={events} location={location} />
      </CalendarStyles>
      <EventList events={filteredEvents}>Events Page</EventList>
    </div>
  )
}

export const query = graphql`
  query EventsQuery {
    events: allSanityEvent(
      filter: { approved: { eq: true } }
      sort: { fields: eventDate, order: ASC }
    ) {
      nodes {
        id
        title
        eventDate
        endDate
        description
        slug {
          current
        }
        categories {
          name
          invert
          color {
            hex
          }
        }
        legacyImage: image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        eventImage {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
