import React, { Component } from 'react'
import _ from 'lodash'
import queryString from 'query-string'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import { replaceAllSearchParams } from '../../utils/utils'
import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css'

const CalendarStyles = styled.div`
  font-size: 1.3rem;

  .bigCal {
    width: 100%;
    height: 70vh;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .red {
    color: white;
    background: red;
  }

  .light:hover {
    color: white;
  }

  .hidden {
    display: none;
  }

  .btn-container {
    width: 100%;
    text-align: right;

    .toggle-btn {
      font-size: 1.6rem;
      margin-bottom: 10px;
      border: 0;
      padding: 0;
      padding: 10px 15px;
      border-radius: 5px;
      background-color: var(--link-hover);
      color: white;
    }
  }

  @media all and (min-width: 1136px) {
    .bitCal {
      height: 75vh;
      width: 66vw;
    }
  }
`

const EventStyles = styled.span.attrs(({ styles }) => {
  const { backgroundColor, color, curStyles } = styles

  return {
    backgroundColor,
    color,
    curStyles,
  }
})`
  .rbc-event {
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    ${props => props.curStyles}
  }
`

const colorEvents = event => {
  const curStyle = event.children.props.style
  const bgColor = _.get(event, ['event', 'categories', '0', 'color', 'hex'], '')
  const makeWhite = _.get(event, ['event', 'categories', '0', 'invert'], true)

  return (
    <EventStyles
      className={event.event.display ? '' : 'hidden'}
      styles={{
        backgroundColor: `${bgColor}`,
        color: makeWhite ? '#ffffff' : '#000000',
        border: `1px solid ${bgColor}`,
        curStyles: curStyle,
      }}
    >
      {event.children}
    </EventStyles>
  )
}

class BigCal extends Component {
  constructor() {
    super()
    moment.locale('en')
    this.localizer = momentLocalizer(moment)
    this.hideButton = <button type="button">Hide Calendar</button>
    this.showButton = <button type="button">Hide Calendar</button>
    this.state = {
      showCal: true,
    }
  }

  getSearchParam(loc) {
    const search = loc ? loc.search : this.location.search

    if (search) {
      return _.get(queryString.parse(search), ['cat'], null)
    }

    return null
  }

  darken = data => {
    const { location } = this.props
    const searchParamString = replaceAllSearchParams(location, {
      event: data.slug,
    })

    navigate(`?${searchParamString}`)
  }

  toggleCal() {
    const { showCal } = this.state
    this.setState({ showCal: !showCal })
  }

  modifyEvents() {
    const { events, location } = this.props
    const cat = this.getSearchParam(location)

    return events
      .map(event => {
        let shouldDisplay = true
        if (cat) {
          const eventCats = event.categories.map(c => c.name)
          shouldDisplay = eventCats.includes(cat)
        }

        return {
          title: event.title,
          tooltip: `${event.title} - ${event.categories
            .map(c => c.name)
            .join(', ')}`,
          start: new Date(event.eventDate),
          allDay: false,
          end: new Date(event.endDate),
          slug: event.slug.current,
          categories: event.categories,
          display: shouldDisplay,
        }
      })
      .filter(e => e.display)
  }

  render() {
    const events = this.modifyEvents()

    const readEvent = e => {
      navigate(e.slug)
    }

    const { showCal } = this.state

    let defaultView = 'month'

    if (typeof window !== 'undefined') {
      defaultView = window.innerWidth < 600 ? 'agenda' : 'month'
    }

    return (
      <>
        <CalendarStyles>
          <div className="btn-container">
            <button
              onClick={() => {
                this.toggleCal()
              }}
              className="toggle-btn"
              type="button"
            >
              {showCal ? 'Hide Calendar' : 'Show Calendar'}
            </button>
          </div>

          <BigCalendar
            selectable
            className={`bigCal ${showCal ? '' : 'hidden'}`}
            showMultiDayTimes
            localizer={this.localizer}
            defaultView={defaultView}
            // views={['month', 'week', 'day',]}
            defaultDate={new Date()}
            events={events}
            tooltipAccessor="tooltip"
            // NOTE - previously this.darken for onSelectEvent
            onSelectEvent={readEvent}
            // onDoubleClickEvent={readEvent}
            onSelecting={() => false}
            components={{
              eventWrapper: colorEvents,
            }}
          />
        </CalendarStyles>
      </>
    )
  }
}

export default function Calendar({ events, location }) {
  return (
    <>
      <BigCal events={events} location={location} />
    </>
  )
}
